import { Button, Popconfirm, Spin, Table, Tooltip } from "antd";
import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  fbUpdateStatus,
  getOrders,
  getStoreItems,
} from "../../../services/firebaseService/endPoints/admin/store";
import { json2csv } from "json-2-csv";
import {
  getCurrencyUnicode,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import logger from "../../../utils/logger";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import "./orders.css";

class Orders extends React.Component {
  state = {
    tableData: [],
    allData: [],
    editStatus: false,
    editableOrderId: "",
    editedRecords: {},
    statusFilterValue: "all",
    shippingTypeFiler: "all",
    searchElem: "",
    loading: false,
  };

  updateStatus = async () => {
    await fbUpdateStatus(
      this.props.currentGroup,
      this.state.editedRecords
    ).then(() => {
      this.fetchData();
    });
  };

  handleDownloadCsv(tableData) {
    try {
      let result = [];
      let recIds = [];
      tableData.forEach((record) => {
        let date = moment.unix(Math.floor(record.timestamp / 1000));
        let shippingAmount = record.shippingAmount;
        if (recIds.includes(record.id)) {
          shippingAmount = "";
        } else {
          recIds.push(record.id);
        }

        let row = {
          "Order#": record.id,
          "Fulfilled?": record.status,
          Shipping: record.shippingOption,
          Date: date.isValid() ? date.format("MMM D") : "",
          Name: record.customerName,
          Item: record.itemName,
          Quantity: record.quantity,
          Size: record.size || "",
          Color: record.color || "",
          "Order Amount": record.price,
          "Shipping $": shippingAmount,
          // "Address": record.address || '',
          Address1: record.address1 || "",
          Address2: record.address2 || "",
          City: record.city || "",
          State: record.state || "",
          Country: record.country || "",
          Zip: record.zip || "",
          Email: record.email || "",
          Phone: record.phone || "",
        };
        result.push(row);
      });

      const csv = json2csv(result);

      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([csv, "Text"], { type: "text/csv" })
      );
      a.download = "orders.csv";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      this.props.setDownloading(false);
    } catch (error) {
      this.props.setDownloading(false);
    }
  }

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  render() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    let columns = [
      {
        width: 50,
        title: "Actions",
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: (
              <div className=" flex-center">
                {data && data.id && (
                  <i
                    className="fa fa-pencil cursor-pointer ml-3"
                    onClick={() => {
                      this.setState({
                        editStatus: true,
                        editableOrderId: record.id,
                      });
                    }}
                  ></i>
                )}
              </div>
            ),
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
      },
      {
        title: "Order#",
        width: 150,
        dataIndex: "id",
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: <span>{data}</span>,
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
        sorter: (a, b) => {
          return a.id.localeCompare(b.id);
        },
      },
      {
        title: "Fulfilled?",
        dataIndex: "status",
        className: "header-white",
        render: (data) => {
          let color =
            data === "unfulfilled" || data === "unclaimed" ? "red" : "green";
          let value = "Unfulfilled";
          switch (data) {
            case "pickedup":
              value = "Picked up";
              break;
            case "shipped":
              value = "Shipped";
              break;
            case "refunded":
              value = "Refunded";
              break;
            case "unclaimed":
              value = "Unclaimed";
              break;
            default:
          }
          return <span style={{ color: color }}>{value}</span>;
        },
        // sorter: (a, b) => a.status.localeCompare(b.status),
        onCell: (record) => {
          if (record.itemIndex > 0) {
            return { colspan: 1 };
          }
        },
      },
      {
        title: "Shipping",
        dataIndex: "shippingOption",
        className: "header-white",
        render: (data, record) => {
          let value = "Shipping";
          if (data === "pickup") {
            value = "Pick up";
          }

          let obj = {
            children: <span>{value}</span>,
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
        // sorter: (a, b) => a.shippingOption.localeCompare(b.shippingOption),
      },
      {
        title: "Date",
        dataIndex: "timestamp",
        width: 150,
        className: "header-white",
        render: (data, record) => {
          let date = moment.unix(data / 1000);
          if (date.isValid()) {
            let obj = {
              children: (
                <span>
                  {moment.unix(Math.floor(data / 1000)).format("MMM D, YYYY")}
                </span>
              ),
              props: {},
            };
            obj.props.rowSpan = record.rowSpan;

            return obj;
          }
          return "";
        },
        sorter: (a, b) => a.timestamp - b.timestamp,
      },
      {
        title: "Name",
        dataIndex: "customerName",
        className: "header-white",
        width: 200,
        render: (data, record) => {
          let obj = {
            children: (
              <div
                style={{
                  whiteSpace: "nowrap",
                  lineBreak: "unset",
                  wordBreak: "keep-all",
                  paddingRight: 20,
                }}
              >
                <span>{data}</span>
              </div>
            ),
            props: {},
          };
          // obj.props.rowSpan = record.rowSpan;
          return obj;
        },
        sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      },
      {
        title: "Item",
        width: 200,
        dataIndex: "itemName",
        className: "header-white",
        render: (data) => <span>{data}</span>,
        onCell: (record) => {
          if (record.itemIndex > 0) return { colspan: 1 };
        },
        // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: 50,
        className: "header-white",
        render: (data) => <span>{data}</span>,
        onCell: (record) => {
          if (record.itemIndex > 0) return { colspan: 1 };
        },
      },
      {
        title: "Size",
        width: 200,
        dataIndex: "size",
        className: "header-white",
        render: (data) => <span>{data}</span>,
        onCell: (record) => {
          if (record.itemIndex > 0) return { colspan: 1 };
        },
      },
      {
        title: "Color",
        width: 50,
        dataIndex: "color",
        className: "header-white",
        render: (data) => <span>{data}</span>,
        onCell: (record) => {
          if (record.itemIndex > 0) return { colspan: 1 };
        },
      },
      {
        title: (
          <span>
            Order Amount
            <Tooltip
              title={
                <div style={{ maxWidth: 400 }}>
                  <span>Amount does not include shipping.</span>
                </div>
              }
              placement="topLeft"
            >
              <span className="border-0">
                &nbsp;<i className="fa fa-question-circle-o"></i>
              </span>
            </Tooltip>
          </span>
        ),
        width: 140,
        className: "header-white",
        render: (record) => {
          let price = record.price;
          if (price === "included") {
            return <span>{price}</span>;
          }
          let total = parseFloat(record.price) * parseInt(record.quantity);
          total = total.toFixed(Math.round(total) === total ? 0 : 2);
          return (
            <span>
              {groupCurrency}
              {total}
            </span>
          );
        },
        onCell: (record) => {
          if (record.itemIndex > 0) return { colspan: 1 };
        },
      },

      {
        title: `Shipping ${groupCurrency}`,
        dataIndex: "shippingAmount",
        width: 110,
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: (
              <span>
                {groupCurrency}
                {data}
              </span>
            ),
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
      },
      {
        title: "Address",
        width: 450,
        dataIndex: "address",
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: <span>{data}</span>,
            props: {},
          };
          // obj.props.rowSpan = record.rowSpan;
          return obj;
        },
      },
      {
        width: 150,
        title: "Email",
        dataIndex: "email",
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: <span>{data}</span>,
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
      },
      {
        width: 300,
        title: "Phone",
        dataIndex: "phone",
        className: "header-white",
        render: (data, record) => {
          let obj = {
            children: <span>{data}</span>,
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;
          return obj;
        },
      },
    ];

    const columnsWidth = columns.reduce((agr, item) => {
      return agr + (item.width || 0);
    }, 0);

    const editableTableCols = [
      {
        title: "Status",
        dataIndex: "status",
        className: "header-white",
        render: (data, record) => {
          let editedRecords = this.state.editedRecords || {};
          editedRecords[record.id] = { ...(editedRecords[record.id] || {}) };

          let value =
            editedRecords[record.id][
              record.items.length - 1 - record.itemIndex
            ] || data;
          return (
            <select
              value={value}
              onChange={(e) => {
                let editedRecords = this.state.editedRecords || {};
                editedRecords[record.id] = {
                  ...(editedRecords[record.id] || {}),
                };
                editedRecords[record.id][
                  record.items.length - 1 - record.itemIndex
                ] = e.target.value;
                this.setState({ editedRecords });
              }}
            >
              <option value={"unfulfilled"} key="select">
                --Select--
              </option>
              <option value={"shipped"} key="shipped">
                Shipped
              </option>
              <option value={"pickedup"} key="pickedup">
                Picked up
              </option>
              <option value={"refunded"} key="refunded">
                Refunded
              </option>
              <option value={"unclaimed"} key="unclaimed">
                Unclaimed
              </option>
            </select>
          );
        },
      },
      {
        title: "Status Updated",
        dataIndex: "statusUpdateTimestamp",
        className: "header-white",
        render: (data) => {
          let date = moment.unix(data);
          if (date.isValid() && data) {
            return <span>{moment.unix(data).format("MMM D") || ""}</span>;
          }
          return "";
        },
      },
      {
        title: "Item",
        dataIndex: "itemName",
        className: "header-white",
        render: (data) => {
          return <span>{data}</span>;
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        className: "header-white",
        render: (data) => {
          return <span>{data}</span>;
        },
      },
      {
        title: "Size",
        dataIndex: "size",
        className: "header-white",
        render: (data) => {
          return <span>{data}</span>;
        },
      },
      {
        title: "Color",
        dataIndex: "color",
        className: "header-white",
        render: (data) => {
          return <span>{data}</span>;
        },
      },
    ];

    let tableData = this.state.tableData;
    if (this.state.searchElem) {
      let searchParam = this.state.searchElem;
      tableData = tableData.filter((row) => {
        let itemName = row.itemName || "";
        let customerName = row.customerName || "";
        let email = row.email || "";

        return (
          itemName.toLowerCase().includes(searchParam.toLowerCase()) ||
          customerName.toLowerCase().includes(searchParam.toLowerCase()) ||
          email.toLowerCase().includes(searchParam.toLowerCase())
        );
      });
    }
    if (this.state.statusFilterValue !== "all") {
      if (this.state.statusFilterValue === "unfulfilled") {
        tableData = tableData.filter((row) => row.status === "unfulfilled");
      } else if (this.state.statusFilterValue === "unclaimed") {
        tableData = tableData.filter((row) => row.status === "unclaimed");
      } else {
        tableData = tableData.filter(
          (row) => row.status !== "unfulfilled" && row.status !== "unclaimed"
        );
      }
    }

    if (this.state.shippingTypeFiler !== "all") {
      if (this.state.shippingTypeFiler === "ship") {
        tableData = tableData.filter(
          (row) => row.shippingOption === "shipping"
        );
      } else if (this.state.shippingTypeFiler === "pickup") {
        tableData = tableData.filter((row) => row.shippingOption === "pickup");
      }
    }

    let filteredItemsOrderNoMap = {};

    tableData.forEach((rec) => {
      if (!filteredItemsOrderNoMap[rec.id]) {
        filteredItemsOrderNoMap[rec.id] = [];
      }
      filteredItemsOrderNoMap[rec.id].push(rec.itemId);
    });

    let indices = [];
    let lastRecId = tableData.length > 0 && tableData[0].id;

    tableData.forEach((rec) => {
      let items = filteredItemsOrderNoMap[rec.id] || [];
      let index;

      if (rec.id !== lastRecId) {
        indices = [];
        lastRecId = rec.id;
      }

      for (let n = 0; n < items.length; n++) {
        let item = items[n];
        if (item === rec.itemId) {
          if (!indices.includes(n)) {
            index = n;
            indices.push(n);
            break;
          }
        }
      }

      rec.itemIndex = index;
      if (index === 0) {
        rec.rowSpan = items.length;
      } else {
        rec.rowSpan = 0;
      }
      // itemData.itemIndex = items.length - index - 1;
    });

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    if (this.props.download) {
      this.handleDownloadCsv(tableData);
    }

    return (
      <>
        <OrdersReportModal
          open={this.state.editStatus}
          onOk={() => {}}
          onCancel={() => {
            this.setState({
              editStatus: false,
            });
          }}
          updateStatus={() => {
            this.updateStatus().then(() => {
              this.setState({
                editStatus: false,
                editableOrderId: "",
                editedRecords: {},
              });
            });
          }}
          primaryColor={primaryColor}
        >
          <div style={{ paddingTop: 20, width: "100%" }}>
            <Table
              bordered
              columns={editableTableCols}
              dataSource={this.state.tableData.filter(
                (rec) => rec.id === this.state.editableOrderId
              )}
              pagination={false}
              className="table-backdrop"
              bodyStyle={{ backgroundColor: "#ffffff" }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </OrdersReportModal>

        <div className="mx-auto" style={{ width: "100%" }}>
          <div
            className="orders-filter-container row mt-2 mb-2 collapsible-horizontal collapsile-filter"
            style={{ padding: "0px 10px" }}
            collapsible-horizontal
            id="orders-filter"
          >
            <a
              class="icon"
              onClick={() => switchCollapseHorizontal("orders-filter")}
            >
              Filters
            </a>
            <div className="col-md-4 px-2">
              <input
                placeholder="Search item / name / email"
                className="form-control form-control-sm col"
                onChange={(e) => {
                  let value = e.target.value;
                  this.setState({
                    searchElem: value,
                  });
                }}
                value={this.state.searchElem}
                style={{
                  backgroundColor: "transparent",
                  border: "0px none transparent",
                  borderBottom: "2px solid white",
                  color: "black",
                  borderRadius: 0,
                  fontSize: 16,
                }}
              />
            </div>
            <div className="col-md-3 px-2">
              <select
                className="form-control form-control-sm"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  border: "0px none transparent",
                  borderBottom: "2px solid white",
                  color: "black",
                  borderRadius: 0,
                  fontSize: 16,
                }}
                value={this.state.statusFilterValue}
                onChange={(e) => {
                  this.setState({
                    statusFilterValue: e.target.value,
                  });
                }}
              >
                <option value={"all"} key="all">
                  Fulfillment Status (All)
                </option>
                <option value={"fulfilled"} key="fulfilled">
                  Fulfilled
                </option>
                <option value={"unfulfilled"} key="unfulfilled">
                  Unfulfilled
                </option>
                <option value={"unclaimed"} key="unclaimed">
                  Unclaimed
                </option>
              </select>
            </div>
            <div className="col-md-3 px-2">
              <select
                className="form-control form-control-sm"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  border: "0px none transparent",
                  borderBottom: "2px solid white",
                  color: "black",
                  borderRadius: 0,
                  fontSize: 16,
                }}
                value={this.state.shippingTypeFiler}
                onChange={(e) => {
                  this.setState({
                    shippingTypeFiler: e.target.value,
                  });
                }}
              >
                <option value={"all"} key="all">
                  Shipping/Pickup (All)
                </option>
                <option value={"ship"} key="ship">
                  Ship
                </option>
                <option value={"pickup"} key="pickup">
                  Pickup
                </option>
              </select>
            </div>
          </div>
          <Table
            bordered
            columns={columns}
            dataSource={tableData}
            onChange={(pagination, filters, sorter, extra) => {
              let tableData = [...this.state.allData];
              if (sorter.column) {
                tableData.sort((a, b) => {
                  let comparatorValue = sorter.column.sorter(a, b);
                  if (sorter.order === "descend") {
                    comparatorValue = -comparatorValue;
                  }
                  return comparatorValue;
                });
              }

              this.setState({
                tableData,
              });
            }}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            scroll={{ x: columnsWidth }}
            className="table-backdrop"
            bodyStyle={{ backgroundColor: "#ffffff" }}
          />
        </div>
      </>
    );
  }

  fetchData() {
    getStoreItems(this.props.currentGroup).then((storeItems) => {
      getOrders(this.props.currentGroup).then((orders) => {
        let tableData = [];
        orders.forEach((order) => {
          let items = order.items || [];
          let orderAmount = items.reduce((acc, value) => {
            return acc + value.price * value.count;
          }, 0);
          orderAmount = orderAmount + parseFloat(order.shippingAmount);
          items.forEach((item, index) => {
            let itemData = { ...order };
            let requiredStoreItem = storeItems.find(
              (storeItem) => storeItem.id === item.id
            ) || { name: "" };
            itemData.itemName = requiredStoreItem.name;
            itemData.quantity = item.count;
            itemData.color = item.color;
            itemData.size = item.size;
            itemData.status = item.status || "unfulfilled";
            itemData.statusUpdateTimestamp = item.statusUpdateTimestamp || "";
            itemData.orderAmount = orderAmount;
            itemData.price = item.price;
            itemData.itemId = item.id;
            if (item.buyerInfo) {
              let address = "";
              if (itemData.shippingOption === "shipping") {
                let data = item;
                if (data.buyerInfo.fullName) {
                  itemData.customerName = data.buyerInfo.fullName;
                }
                if (data.buyerInfo.address1) {
                  address += data.buyerInfo.address1 + ",";
                  itemData.address1 = data.buyerInfo.address1;
                }
                if (data.buyerInfo.address2) {
                  address += data.buyerInfo.address2 + ",";
                  itemData.address2 = data.buyerInfo.address2;
                }
                if (data.buyerInfo.city) {
                  address += data.buyerInfo.city + ",";
                  itemData.city = data.buyerInfo.city;
                }
                if (data.buyerInfo.state) {
                  address += data.buyerInfo.state + ",";
                  itemData.state = data.buyerInfo.state;
                }
                if (data.buyerInfo.country) {
                  address += data.buyerInfo.country + " ";
                  itemData.country = data.buyerInfo.country;
                }

                let addressArr = address.split("");
                addressArr.pop();
                address = addressArr.join("") + " ";
                address = address.replace(/,/g, ", ");

                if (data.buyerInfo.zip) {
                  address += data.buyerInfo.zip;
                  itemData.zip = data.buyerInfo.zip;
                }
              }
              if (address) {
                itemData.address = address;
              }
            }
            tableData.push(itemData);
          });
        });
        tableData.sort((row1, row2) => {
          let timestamp1 = row1.timestamp;
          if (!timestamp1) {
            timestamp1 = 0;
          }
          let timestamp2 = row2.timestamp;
          if (!timestamp2) {
            timestamp2 = 0;
          }
          if (
            (typeof row1.timestamp === "object" &&
              typeof row2.timestamp === "object") ||
            row2.timestamp === row1.timestamp
          ) {
            return row1.id < row2.id ? 1 : -1;
          }
          if (typeof row1.timestamp === "object") {
            return 1;
          }
          if (typeof row2.timestamp === "object") {
            return -1;
          }

          return row2.timestamp - row1.timestamp;
        });
        this.setState({
          tableData,
          allData: tableData,
        });
      });
    });
  }

  componentDidMount() {
    this.fetchData();
    this.changeHeaderColor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchData();
      this.changeHeaderColor();
    }
  }
}

class OrdersReportModal extends React.Component {
  render() {
    return (
      <Modal
        width={700}
        title={""}
        centered
        open={this.props.open}
        onOk={() => {
          this.props.onOk();
        }}
        onCancel={() => {
          this.props.onCancel();
        }}
        footer={[
          <ChantButton
            key="submit"
            type="primary"
            onClick={() => {
              this.props.updateStatus();
            }}
          >
            Save
          </ChantButton>,
        ]}
      >
        {this.props.children}
      </Modal>
    );
  }

  changeHeaderColor = () => {
    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = this.props.primaryColor;
    });
  };
}
const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(Orders);
